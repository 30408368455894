.category-item img {
    max-width: 100%;
    border-radius: 10px;
     max-height: 240px;
}
.mobile-product{
    width: 48px;
    height: 48px;
    background-color: #f6f6f6;
    border-radius: 12px;
    overflow: hidden;
}
.sub-p-mobile{
    margin-top: 4px;
    color: black;
    font-weight: bold;
}
.category-item p {
    text-align: center;
    margin: 10px 0px;
    font-size: 16px;
    color: #000;
}

.category-item a {
    text-decoration: none;
}

.categories-container {
    margin-bottom: 30px;
}

.category-item {
    text-align: center;
}

.subcat-item-container img {
    max-width: 100%;
    border-radius: 5px;
}

.subcat-item-container {
    padding: 10px 20px;
    border: 1px solid #ddd;
}
.subcat-item-container.active {
    padding: 10px 20px;
    border: 1px solid #ddd;
    background-color: var(--secondary_color);
}
.subcat-item-container-mobile{
    margin: 12px 0px 0px 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.subcat-item-container-mobile a{
    text-decoration: none;
}
.subcategories-list {
    border-right: 1px solid #ddd;
    padding: 10px 0px;
}
.top-info-container .d-flex {
    align-items: center;
}

select#sortby {
    padding-top: 4px;
    padding-bottom: 4px;
    color: #3faa4a;
}
.subcategories-list-mobile {
    box-shadow: 1px 0px 3px -3px rgba(0,0,0,0.75);
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    min-width: 84px;
    max-width: 84px;
    /* overflow-y: scroll; */
    box-sizing: border-box;
}

.subcat-item-container a {
    color: #000;
    text-decoration: none;
}
.subcat-item-container:has(> a.active) {
    background:var(--secondary_color);
    border-left: 5px solid #3faa4a;
}
.subcat-item-container a.active img {
    margin-left: -5px;
}
p.sub-p {
    margin: 0px;
    line-height: 13px;
}

.subcat-item-container:hover {
    background: #eafdeb;
}

@media screen and (max-width: 767.98px) { 

    .subcat-item-container:has(> a.active) {
        border-right: 5px solid #3faa4a;
        border-left: none;
    }
    .subcat-item-container.active{
        padding: 15px 2px;
        border: none;
    }
    .subcat-item-container a.active img {
        margin-left: 0px;
    }
}