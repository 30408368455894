.product-img {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.product-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.product-wish-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  z-index: 1;
  padding: 10px;
  border-radius: 50%;
}

.product-item {
  /* border: 1px solid var(--primary_color);  */
  border-radius: 5px;
  width: 282px;
  margin-bottom: 32px;
  margin-right: 20px;
  border: 1px solid #D9D9D9;
}

.product-bottom {
  padding: 24px;
}
.product-item:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.timer {
  background-color: #f8f8f8;
  font-size: 9px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  max-width: fit-content;
  padding: 0px 5px;
  border-radius: 4px;
  align-items: center;
  text-transform: uppercase;
}

.product-item-mobile {
  min-width: 100%;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: 0.5px solid #e8e8e8;
  box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-mobile-para {
  overflow: hidden;
  color: rgb(31, 31, 31);
  font-weight: 600;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  width: 100%;
  height: 36px;
}

.product-item .name {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: normal;
  height: 49px;
  line-height: 20px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0;
  font-family: 'inter', sans-serif;
  text-transform: uppercase;
}

.product-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}
.catfl-right .product_details_item{
  padding-top: 45px;
}
.catfl-right .product_details_item h6{
  font-size: 13px;
  color: #727272;
  font-weight: 300;
  margin: 0;
  font-family: 'inter', sans-serif;
  text-transform: uppercase;
}
.catfl-right .product_details_item h3{
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0;
  font-family: 'inter', sans-serif;
  text-transform: uppercase;
  padding: 6px 0;
}
.catfl-right .product_details_item h5{
  font-size: 15px;
  color: #5A5A5A;
  font-weight: 400;
  margin: 0;
  font-family: 'inter', sans-serif;
  text-transform: capitalize;
}

p.produc-price {
  padding: 0;
  text-decoration: line-through;
  color: rgb(153, 153, 153);
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.product-weight {
  margin: 5px auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

p.price {
  text-decoration: none;
  font-weight: bold;
  padding: 0;
  margin: 0px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'inter', sans-serif;
}

.offer-price {
  margin-top: 4px;
  margin-bottom: 20px;
  font-size: 9px;
  font-weight: 700;
  padding: 1px 4px;
  color: white;
  background-color: rgb(83, 140, 238);
  border-radius: 4px;
  width: fit-content;
}

.product-detail-actions .btn.btn-cart {
  height: 64px;
  width: 179px;
}

.p-price {
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  gap: 8px;
  color: #000;
}
.cart-qty .p-price{
  color: #ffffff;
}
.gap_price{
  display: flex;
  flex-direction: column-reverse;
}

.p-btn {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}

.bill-details .p-btn {
  height: 100%;
  padding-bottom: 7px;
}

.address-container {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.btn.btn-success {
  background-color: #005CEA;
  color: #131921;
}

.btn.btn-cart {
  width: 71px;
  height: 27px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #6A7B9D;
  column-gap: 4px;
}

.product-item .subcat-name {
  margin: 0;
  padding-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: normal;
  height: 28px;
  line-height: normal;
  font-size: 15px;
  color: #5A5A5A;
  font-weight: 400;
  font-family: 'inter', sans-serif;
  text-transform: uppercase;
}

.product-img img {
  max-width: 100%;
  margin: auto;
  width: auto;
}

/* .slick-slider .slick-prev:before, .slick-slider .slick-next:before {
    color: var(--primary_color);
    font-size: 30px;
    margin-left: -5px;
}  */

/* .slider-container {
  position: absolute;
  left: 9px;
  bottom: -41px;
} */

.slider-container {
  position: relative;
}

.grid_6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.grid_5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.slick-arrow,
.left,
.right {
  width: 34px;
  height: 34px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  border: 4px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.left {
  left: 0;
  top: 40%;
}

.right {
  right: 0px;
  top: 40%;
}

.slick-next:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 9px;
  transform: rotate(315deg);
  width: 9px;
  height: 9px;
  border-right: 2px solid rgb(28, 28, 28);
  border-bottom: 2px solid rgb(28, 28, 28);
  border-top: 0px;
  border-left: 0px;
  font-size: 13.5px;
  color: rgb(105, 105, 105);
  font-weight: bold;
}

.slick-prev:before {
  content: "";
  border-right: 2px solid rgb(28, 28, 28);
  border-bottom: 2px solid rgb(28, 28, 28);
  border-top: 0px;
  border-left: 0px;
  font-size: 13.5px;
  color: rgb(105, 105, 105);
  font-weight: bold;
  position: absolute;
  right: 7px;
  top: 9px;
  transform: rotate(135deg);
  width: 9px;
  height: 9px;
}

.products-container {
  margin-bottom: 30px;
  /* border-bottom: 1px solid #ddd; */
  position: relative;
}

.Selectbar.value {
  width: 50px;
}

.view-all {
  text-decoration: none;
  font-size: 20px;
  color: var(--primary_color);
  font-weight: 400;
  margin-right: 10px;
  line-height: 32px;
}

.catagery-items-grid .product-item {
  background: #fff;
}

.catagery-items-grid {
  padding: 30px 0px 12px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-right: 0px;
}

.view-all:hover {
  text-decoration: underline;
}

h2 {
  margin-bottom: 10px;
}

img.cart-img {
  max-width: 30px;
}

.btn-cart-added {
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  background: #005CEA !important;
  color: #fff;
}

.qty {
  flex: 1;
  color: #005CEA;
  margin: 0 12px;
  font-size: 16px;
  font-weight: 600;
}
.qty_add{
  color: #fff !important;
  margin: 0;
}
.custom-plus,
.custom-minus2 {
  position: relative;
  width: 14px !important;
  height: 14px !important;
}


.custom-plus,
.custom-minus {
  position: relative;
  width: 18px;
  height: 14px;
}

.custom-plus::before,
.custom-plus::after,
.custom-minus::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  width: 77%;
  height: 2.1px;
  border-radius: 1px;
  background-color: #005CEA;
}
.custom-plus::before,
.custom-plus::after,
.custom-minus2::before {

  background-color: #fff !important;
}
.custom-plus2::after,
.custom-plus2::before{
  background-color: #005CEA !important;
}

.custom-plus::after {
  transform: rotate(90deg);
}

button.btn-minus {
  flex: 1;
  padding: 0px;
  background: none;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.btn-cart-added:hover,
.btn-cart-added:active {
  color: #fff !important;
}

.product-detail {
  padding: 0px 12px;
  /* border-bottom: 1px solid #ddd; */
  position: relative;
}

.product-detail-info {
  padding: 33px;
  position: sticky;
  top: 86px;
}
.size_chart {
  cursor: pointer;
}
/* Container for options */
.option-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

/* Hide the default radio button */
.option-container input[type="radio"] {
  display: none;
}

/* Style for the label */
.option-container label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px 25px;
  background-color: #fff;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.options-block {
  margin-bottom: 24px;
}
/* Change border color and background on hover */
.option-container label:hover {
  border-color: #007bff;
  background-color: #e6f7ff;
}

/* Change style when the radio button is selected */
.option-container input[type="radio"]:checked + label {
  border-color: #007bff;
  background-color: #007bff;
  color: white;
}

.pdfl-right .size_chart p{
  background: #005CEA;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 11px;
  border-radius: 2px;
  margin: 12px 0;
  color: #fff;
  font-size: 12px;

}
.size_list{
  text-align: start;
}
.size_list h2{
  font-size: 18px;
  font-weight: 600;
  font-family: 'inter', sans-serif;
}
.size_list ul{
  list-style: none;
  display: flex;
  column-gap: 12px;
  padding: 0;
}
.size_list ul li{
  color: #000;
  font-size: 16px;
  background: #fff;
  border-radius: 5px;
  padding: 16px 25px;
  border: 1px solid #000;
}

.product_colors h2, .options-block h2{
  font-size: 18px;
  font-weight: 600;
  font-family: 'inter', sans-serif;
}
.product_colors ul{
  list-style: none;
  display: flex;
  column-gap: 12px;
  padding: 0;
}
/* .product_colors ul li{
  
} */
.slide-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  background: #c4c2d5;
  border-radius: 5px;
}
.play-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;        
  color: white;          
  background-color: rgba(0, 0, 0, 0.5);  
  border-radius: 50%;     
  padding: 8px;   
  cursor: pointer;      
}
.pdfl {
  display: flex;
}

/* .pdfl-left {
  flex: 1.2 1;
} */

.pdfl-right {
  flex: 1;
  /* border-left: 1px solid #ddd; */
  margin-left: 23px;
}

.all-by-brand {
  color: green;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
.description-container {
  padding: 20px 45px;
  margin-top: 80px;
}

.title {
  border-top: 1px solid #D9D9D9; /* Top border */
  border-bottom: 1px solid #D9D9D9; /* Bottom border */
  padding: 20px 0; /* Space inside the borders */
  margin: 0; /* Remove extra margin */
  font-size: 18px; /* Adjust font size */
  font-weight: 600;
  font-family: 'inter', sans-serif;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.bottom-text {
  margin-top: 10px; /* Space above the text */
  font-size: 16px; /* Adjust text size */
  color: #000; /* Optional color for the text */
  font-weight: 400;
  font-family: 'inter', sans-serif;
  line-height: 27px;
  
}


.product-detail-img img {
  width: 455px;
  border: 1px solid #D9D9D9;
  height: auto;
}
.product_detail_images{
  display: flex;
      justify-content: normal;
      align-items: center;
      position: absolute;
      bottom: -58px;
      left: 45px;
  }
  .product_detail_images div{
    border: 1px solid #6666667f;
  }
  .gap_price .price{
    padding: 0;
    margin: 0px;
    font-size: 18px;
    line-height: 14px;
    font-weight: 600;
    font-family: 'inter', sans-serif;
    padding-bottom: 26px;
    padding-top: 8px;
  }
  .gap_price .price p{
    font-size: 18px;
    font-weight: 600;
    font-family: 'inter', sans-serif;
  }


.product-detail-img {
  text-align: center;
  padding-top: 33px;
  position: relative;
 
}
.pdfl-left .slider-container img {
  cursor: pointer;
}
.product_slider_header{
  position: relative;
}

.product-detail .p-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  font-family: 'inter',sans-serif;
  text-transform: uppercase;
}
.product_details_header{
  display: flex
  ;
      justify-content: space-between;
      align-items: center;
}
.product_share{
  border: 1px solid #59525265;
    /* padding: 7px; */
    font-size: 21px;
    border-radius: 5px;
    width: 60px;
    height: 60px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.product-actions .btn-product-detail{
  padding: 29px 90px;
  background: none !important;
  border: 1.3px solid #80B2FF !important;
  color: #000;
  border-radius: 5px;
}
.product-actions   .product_additem button{
  border: none;
  background: #005CEA;
  padding: 18px 58px;
  border-radius: 5px;
  color: #fff;

  
}
.product_wishlist_icon{
  border: 1px solid #59525265;
  padding: 20px;
  border-radius: 5px;
}

.product-detail .produc-price {
  font-weight: bold;
}

.product-detail-actions .product-actions {
  display: flex;
  margin: 16px 0;
}

.product-description {
  padding-right: 60px;
  padding-bottom: 60px;
}

.product-description .desc-details {
  color: #666666;
}

.product-description .desc-details {
  color: #666666;
}

p.why-title {
  font-size: 16px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  margin-top: 24px;
  margin-bottom: 8px;
}

.why-cards img {
  padding-bottom: 8px;
  width: 100%;
}

.why-cards p {
  font-size: 12px;
  /* margin: 0px; */
}

.why-bold-text {
  color: #000;
  line-height: 14px;
  margin-bottom: 4px;
}

.why-text-color {
  color: #666;
  line-height: 14px;
}

.product-description .desc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.product-description-mobile {
  display: none;
}

.discount_icon_container {
  background-image: url(./images/discount_sticker.webp);
  background-repeat: no-repeat;
  background-size: 28px 29px;
  position: absolute;
  top: 0px;
  left: 22px;
  z-index: 1;
}

.discount_percentage {
  font: Roboto;
  font-weight: 800;
  line-height: 9.38px;
  font-size: 9px;
  color: #ffffff;
  height: 29px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slick-slide {
  position: relative;
}
.custom-message {
  background-color: #000000a3;
  color: #f7fff9;
  text-align: center;
  z-index: 100;
  font-size: 12px;
  padding: 10px;
  display: block;
  width: auto;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  overflow: visible;
}
@media screen and (max-width: 767.98px) {
  .product-img img {
    max-width: 100%;
  }

  .grid_6,
  .grid_5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .all-by-brand {
    font-size: 14px;
  }
}

@media screen and (max-width: 767.98px) {
  .right{
    top: 40%;
    right: 0px;
  }
  .slick-slider .slick-next:before {
    margin-left: 0px;
  }

  .slick-next {
    right: 0px;
    z-index: 9;
  }

  .slick-prev {
    left: 0px;
    z-index: 9;
  }

  .view-all {
    font-size: 13px;
    font-weight: 600;
    margin-right: 14px;
  }

  .catagery-items-grid {
    padding: 8px;
    grid-template-columns: auto;
  }

  .product-item {
    margin-right: 0;
  }

  .display-categories .product-item {
    max-width: 160px;
  }
  .p-price {
    flex-direction: column;
    gap: 0;
  }

  p.price {
      font-size: 13px;
      line-height: normal;
  }

  p.produc-price {
      font-size: 13px;
      line-height: normal;
  }

  .product-bottom {
    padding: 8px;
  }
  .product-item .name {
    font-size: 13px;
  }
  .product-item .subcat-name {
    font-size: 13px;
    height: 22px;
  }
  .btn.btn-cart {
    font-size: 12px;
    font-weight: 600;
  }

  .product-detail .p-title {
    font-size: 18px;
  }

  .pdfl {
    flex-direction: column;
  }

  .product-detail-img img {
    max-width: 100%;
  }

  .product-description {
    display: none;
  }

  .product-description-mobile {
    display: block;
    padding-top: 40px;
  }

  .product-detail-info {
    padding: 12px;
  }

  .product-detail-actions .product-actions {
    width: 60px;
  }

  .pdfl-right {
    border: none;
  }
}

@media screen and (max-width: 375px) {
  .catagery-items-grid {
    grid-template-columns: auto auto;
  }
}

.variant-container {
  margin-top: 30px;
}

.variant-type {
  font-size: 14px;
  margin-top: 2px;
  font-weight: 500;
  color: #878787;
  width: 110px;
  min-width: 110px;
}

.variant {
  display: flex;
  margin-bottom: 15px;
}

.variant-type-container {
  display: flex;
}

.variant-type-item {
  margin: 0 12px 4px 0;
  font-weight: bold;
  border: 2px solid #ddd;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.variant-type-item:hover {
  border: 2px solid #a72b89;
  cursor: pointer;
}

.selected {
  border: 2px solid #a72b89;
}

.nav-content {
  display: flex;
  justify-content: end;
  align-items: center;

  padding-left: 30px;
}
.nav-content .navbar-nav{
  padding-left: 27px;
}

@media screen and (max-width: 900px) {
  .right{
    top: 40%;
    right: 0px;
  }
  .product-description-mobile {
    display: block;
    padding-top: 40px;
  }

  .product-description-mobile .desc-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .next-arrow {
    position: absolute;
    right: 40px;
    top: 50%;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .next-arrow {
    position: absolute;
    right: 38px;
    top: 50%;
  }

  .catagery-items-grid {
    grid-template-columns: auto auto auto;
  }

  .custom-message {
    font-size: 12px;
    padding: 8px;
  }
}

@media screen and (max-width: 425px) {
  .next-arrow {
    position: absolute;
    right: 31px;
    top: 50%;
  }
  .grid_6,
  .grid_5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 375px) {
  .next-arrow {
    position: absolute;
    right: 13px;
    top: 50%;
  }
}

@media screen and (max-width: 320px) {
  .next-arrow {
    position: absolute;
    right: 4px;
    top: 50%;
  }
}
