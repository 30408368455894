.main-banner {
    width: 100%;
}

.fullwidth-banner {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 15px 12px;
}

@media screen and (max-width: 767.98px) {
    .fullwidth-banner {
        padding: 0px 12px;
    }

    .main-banner {
        height: auto;
        margin-top: 0px;
    }
}