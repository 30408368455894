.order {
    /* margin-top: 2rem; */
    margin: 2rem 30px;
}

.image {
    max-width: 31px;
}

.status_badge {
    font-size: 0.8rem;
    color: white;
    font-weight: 600;
}

.status_badge>div {
    border-radius: 44px;
    padding: 2px 8px;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    text-align: center;
}

.green {
    background-color: var(--primary_color);
    border-radius: 44px;
}

.grey {
    background-color: rgb(153, 153, 153);
    border-radius: 44px;
}

.view_details>a {
    text-decoration: none;
    color: green;
    border: 0.5px solid black;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.no-orders {
    font-weight: bold;
    font-size: 2rem;
    padding-top: 5rem;
    text-align: center;
}

.order-overflow {
    border-left: 1px solid #eee;
    overflow-y: scroll;
    height: 80vh;
}

.view-button {
    padding: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
}

.order-history {
    font-size: 25px;
    font-weight: 500;
    padding: 15px;
    background-color: #F8F8F8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sticky-container {
    position: sticky;
    top: 0;
    z-index: 5;
    padding: 0px 30px;
    background: white;
}


@media screen and (max-width: 550px) {
    .order_id {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px
    }

    .gap-4 {
        gap: 1rem !important;
    }

    .order {
        margin: 0px;
    }

    .order-history {
        font-size: 20px;
    }

    .sticky-container {
        padding: 0px;
    }
}