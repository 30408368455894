.navbar-brand img {
  margin-left: 40px;
  max-height: 76px;
  max-width: 410px;
}
a.navbar-brand {
  padding: 0;
  /* width: 143px; */
}
.navbar-bottom {
  margin-top: 85px;
}
.navbar-container .navbar {
  background: #ffffff;
  border: 1px solid #EEEEEE;
  height: 85px;
}
.subcast-icon {
  font-size: 25px;
}
/* .fixed-top {
  top: 40px;
} */
.grocery-essential {
  width: 100vw;
  background-color: var(--primary_color);
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  height: 40px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  z-index: 20;
}
.grocery-essential-ul {
  display: flex;
  align-items: center;
  gap: 18px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.grocery-essential-li {
  display: flex;
  align-items: center;
}
.grocery-essential-para {
  font-size: 0.75rem;
  line-height: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}
.grocery-essential-data {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
}
.icon {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  /* display: inline-flex; */
}
.v-divider::before {
  height: 85px;
  background: #eeeeee;
  width: 1px;
  position: absolute;
  content: "";
  top: -42px;
}

.v-divider {
  position: relative;
  margin-left: 10px;
  margin-right: 48px;
}
.cart-container {
  display: flex;
  min-width: 112px;
  height: 50px;
  /* height:52px; */
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  background: var(--primary_color);
  /* margin-right: 20px; */
}
.cart-icon-holder {
  font-size: 28px;
  margin-right: 8px;
  flex: 1;
}
.cart-qty p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: #ffffff;
}
.cart-qty {
  flex: 3;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.cart-container:hover {
  background-color: #fff;
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
}
.show-tablet {
  display: none;
}

.popup-cart.zindex-0 {
  z-index: 0;
}
.popup-cart .modal-content {
  border-radius: 0;
  height: 100vh;
  background-color: #F5F7FD;
}

.popup-cart.modal.show .modal-dialog {
  position: fixed;
  right: 0;
  top: 0;
  margin: 0;
  width: 38%;
}
.model{
  --bs-modal-width: none !important;
}

.modal-title.h4 {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.modal-tile-back.modal-title.h4 {
  color: var(--primary_color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}
.btn-proceed {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  --bs-btn-border-radius: 0;
  background-color: #005CEA;
  margin: 0;
}
.btn-proceed p {
  margin-bottom: 0;
  font-size: 16px;
}
/* .btn-proceed:hover,
.btn-proceed:active {
  background: #fff;
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
} */
p.p-qty {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}
.total-text {
  font-size: 11px;
  font-weight: 400;
}
p.p-pay {
  position: relative;
  margin-right: 20px;
  color: #fff;
}
.modal-footer .btn-proceed {
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.address-container .form-check-input, .address-container .address-label {
  cursor: pointer;
}

.cartimg {
  width: 200px;

}
.product_size.custom_inputs {
  border: 1px solid #ddd;
}
/* p.p-pay::after {
  content: "";
  color: white;
  width: 10px;
  height: 10px;
  border-top: 1px solid white;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  border-right: 1px solid white;
} */
p.p-delivery-time {
  font-weight: 700;
  margin-bottom: 0;
}
.delivery-location {
  flex: 1;
  width: 100%;
  background: #fff;
  color: #727272;
}
svg.home-add {
  font-size: 40px;
}
.delivery-location p {
  margin-bottom: 0px;
}
.del-address {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
p.add-type {
  font-weight: 700;
}
img.cart-display-img {
  max-width: 100%;
  border: 1px solid #D9D9D9

}
.popup-cart .cart-modal-rn {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  background-color: rgb(245, 247, 253);
}
.popup-cart .cart-modal-address {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  background-color: rgb(245, 247, 253);
}
.card_head_section{
  display: flex;
      justify-content: space-between;
      align-items: center;
}
.cart_start img{
  width: 25%;
}
/* .product_price_catalog{
  display: flex;
} */
.cart_left{
  padding: 9px 0 0 0px;
  margin-top: 0px;
  text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
}
.cart_headname{
   font-size: 20px;
   font-weight: 600;
   color: #000; 
}
p.cart-item-price {
  font-weight: 700;
}
.productItem p {
  margin-bottom: 2px;
}
.productItem {
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}
p.cart-total-price {
  font-weight: 700;
}
.search-form button {
  background: no-repeat;
}
.search-form input {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.search-form button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
}
.search-form {
  flex: auto;
}
.dropdown-item {
  margin-bottom: 2px;
  color: #666;
  font-size: 14px;
}
.show-search {
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  color: rgb(102, 102, 102);
  margin-top: 200px;
  font-size: 44px;
}

@media screen and (max-width: 991.98px) {
  .show-tablet {
    display: block;
  }
  .show-desktop {
    display: none;
  }
  .cart-container {
    position: fixed;
    z-index: 9;
    bottom: 10px;
    width: calc(100% - 20px);
    display: flex;
    left: 10px;
    right: 10px;
  }
  .popup-cart .cart-modal-rn {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
  }
  .popup-cart .cart-modal-address {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
  }
}
/* ul.navbar-nav a {
  color: #fff;
} */
div.btn.cart-container {
  background: #005CEA;
}
.cart-modal-rn {
  overflow-y: scroll;
  background-color: rgb(245, 247, 253);
}
.cart-delviry-rn {
  background-color: rgb(255, 255, 255);
}
.cart-modal-rn-address {
  min-width: 400px;
  height: calc(100vh - 150px);
  background-color: rgb(245, 247, 253);
}
.cart-modal-rn-address-mobile {
  height: calc(100vh - 200px);
  background-color: rgb(245, 247, 253);
}
.nav-locat-icon {
  text-align: center;
  font-size: 30px;
  color: rgb(102, 102, 102);
}
.form-nav-input {
  width: 100%;
  margin-right: 10px;
  max-width: 1090px;
}

.form-search input {
  padding-left: 50px;
  border-radius: 33px;
}

.form-search .search-icon {
  top: 5px;
}
form.form-nav-input a {
  text-decoration: auto;
}
.form-controll {
  border: 1px solid black;
  border-radius: 5px;
  padding: 12px;
  margin: 15px;
}

.form-controll > input,
.form-control > input {
  width: 90%;
  border: none;
  margin-left: 30px;
}

.form-controll > input:focus,
.form-control > input:focus {
  border: none;
  outline: none;
}

.search-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 12px;
  top: 8px;
  color: black;
}
.search-div input {
  border-radius: 33px;
  background: #E4EEFF;
  color: rgb(130, 130, 130);
  height: 48px;
}

.placeholderEle {
  padding-left: 45px;
  color: #666;
}
.login-btn {
  font-size: 18px;
  font-weight: 400;
  color: #101A39;
  padding: 0px;
}
.location-nav-main {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  /* align-items: center; */
  display: flex;
  font-size: 16px;
  flex-direction: column;
  cursor: pointer;
  width: 167px;
}
.location-title {
  font-family: "Inter", sans-serif;
  color: #101A39;
  font-weight: 800;
  /* margin-bottom: 6px; */
  font-size: 18px;
}
.location-name {
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 210px;
  text-overflow: ellipsis;
}
.empty-img {
  max-width: 100%;
  width: 144px;
  height: 144px;
  overflow: hidden;
}
.Empty-cart {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
  padding: 16px;
}
.navBar-mobile-main {
  position: sticky;
  top: 0px;
  width: 100%;
  background: #fff;
  z-index: 99;
}
.nav-mobile-header {
  height: auto;
  padding-bottom: 12px;
}
.nav-mobile-location {
  align-items: center;
  display: flex;
  width: auto;
  justify-content: space-between;
  margin-bottom: 10px;
}
.nav-mobile-time {
  color: rgb(0, 0, 0);
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.nav-mobile-store {
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 156px;
  text-overflow: ellipsis;
}
.account-mobile {
  width: 32px;
  height: 32px;
}

.account-mobile-nav {
  background-color: #fff;
}
.account-number {
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.user-account-number {
  padding: 16px;
}
.user-account-number-text {
  font-size: 16px;
  font-family: Okra;
  color: rgb(102, 102, 102);
}
.account-details {
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.account-padding {
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
}
.account-info {
  font-size: 12px;
  margin-bottom: 8px;
  color: rgb(153, 153, 153);
  display: block;
}
.order-histoy {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.order-icon {
  color: rgb(102, 102, 102);
  background-color: rgb(247, 247, 247);
  margin-right: 16px;
  border-radius: 12px;
  font-size: 24px;
}
.icon-name {
  font-size: 16px;
  color: black;
  display: inline-block;
}

.loginfooter.modal-footer {
  display: initial;
  text-align: center;
}
.nav-mobile-location1 {
  align-items: center;
  display: flex;
  width: auto;
  gap: 10px;
}
.loginfooter.modal-footer p {
  font-size: 12px;
  color: #666666;
}
.loginfooter.modal-footer a {
  font-size: 12px;
  color: #3faa4a;
}

.leftarroworder {
  font-size: 30px;
}
.p-avalibile {
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 24px;
}
.not-avalible {
  color: rgb(0, 0, 0);
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
}
.min-value-error {
  color: orange;
  font-weight: bold;
}
.search-mdicon {
  width: 30px;
  height: 30px;
  left: 12px;
  top: 8px;
  color: black;
}
.nav-mobile-common {
  display: flex;
  align-items: center;
  padding: 12px;
}
.navmbf1 {
  flex: 1;
}

.navmbf2 {
  flex: 6;
}

.navmbf3 {
  flex: 1;
}
.login-head__text {
  color: #333;
  font-size: 21px;
  line-height: normal;
  margin: 0;
  text-align: center;
}
.Selectbar {
  font-size: 14px;
  font-weight: 500;
  border-color: #ddd;
  border-radius: 3px;
  border-width: 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #3faa4a;
}
.weight--light {
  font-weight: 200 !important;
}
.login-head {
  padding: 42px 24px 0;
  display: none;
}
.login-header-main {
  text-align: center;
  padding: 20px 0;
  display: none;
}
.modal-body{
  padding: 20px;
}
.loginfooter {
  text-align: center;
  display: block;
  padding-bottom: 32px;
}
.loginfooter p{
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
}
.loginfooter a
{
  font-size: 24px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #fff;
    text-decoration: none;
}
/* .loginfooter a {
  font-size: 12px;
  color: #3faa4a;
  margin-right: 10px;
} */
.login-help {
  display: block;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
.icon-info {
  width: 15px;
  height: 15px;
  margin-left: 6px;
  cursor: pointer;
  transition: color 0.3s;
}
.icon-info:hover {
  background-color: #0000003b;
}
.modal-footer-viewCart {
  padding: 10px;
  color: #318616;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
}
#contained-modal-title-vcenter {
  font-size: 16px;
  color: #1f1f1f;
}
.modal-body-viewCart h2 {
  font-size: 18px;
  letter-spacing: 1px;
  word-spacing: 1px;
}
.modal-body-viewCart p {
  font-size: 13px;
  letter-spacing: 1px;
  word-spacing: 1px;
}
.weight--semibold {
  font-weight: 700;
  color: #fff;
  font-size: 40px;
  padding-top: 36px;
}
.login_page_content .login_page_content img{
  width: 82%;
  margin-top: 135px;
}
.login_page_right{
  width: 330px;
  padding-left: 30px;
}
.login_page_right img{
  width: 82%;
  margin-top: 100px;
 
}
.login_page_content{
  padding: 0 10px 0 10px;
}
.login_page_right h1{
  font-size: 35px;
  text-align: left;
  padding-top: 60px;
  font-weight: 600;
  font-family: 'inter' , sans-serif;
  margin-bottom: 15px;
}
.login_page_right p{
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  font-family: 'inter' , sans-serif;
}


/* Number input */
.num-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.num-input[type=number] {
  -moz-appearance: textfield;
}

.input-prefix{
  align-items: center;
  border-radius: 3px;
  padding: 11px;
  background-color: #fff;
  text-align: start;
  padding-left: 44px;
  font-size: 24px;
  font-weight: 600;
  margin-top: 62px;
}

.num-input{
  border: none;
  outline: none !important;
}
/* .modal-dialog{
  max-width: none !important;
} */
.logoin_page_header{
  display: flex;
}
.modal-dialog.modal-dialog-centered .modal-header {
  border-bottom: none;
  position: absolute;
  right: 5px;
  top: -1px;
  z-index: 9;
}
.modal-content{
  width: auto !important;
  margin: auto !important;
  --bs-modal-width: none !important;
  max-width: none !important;
  position: relative;
}
.login-popup.modal .modal-dialog{
  max-width: none !important;
}
.modal-content
.login_apge_left{
  width: 570px;
  background-color:#005CEA;
  text-align: center;
}

.navbar-container.lite_header section.sub-categories-menu {
  display: none;
}
.navbar-container.lite_header .location-nav-main {
  display: none;
}
.navbar-container.lite_header .search-div input {
  width: 0px;
}
.navbar-container.lite_header .search-form {
  flex: none;
}
.navbar-container.lite_header .navbar-expand-lg .navbar-collapse {
  display: flex;
  flex-basis: auto;
  justify-content: flex-end;
}
.navbar-container.lite_header  .search-div input {
  height: 40px;
}
.navbar-container.lite_header .search-icon {
  left: 15px;
  top: 5px;
}
li.nav-item.lite-menu {
  padding: 8px;
  cursor: pointer;
}
.navbar-container.lite_header .nav-content {
  padding-left: 0;
}
@media only screen and (max-width: 991.98px) {
  .popup-cart.modal.show .modal-dialog{
    width: 100%;
  }
  .num-input{
    width: 100%;
  }
}
@media screen and (max-width: 767.98px) {
  .navbar-brand img {
      max-width: 160px;
      margin-left: auto;
      margin-right: auto;
  }

  .popup-cart .cart-modal-address {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
  }
  .cart_left{
    width: 118px;
  }
  .modal-header .btn-close{
    background-color: #fcfcfc84;
    opacity: .8;
  }
  .num-input{
    width: 100%;
  }
  .popup-cart.modal.show .modal-dialog{
    width: 100%;
  }
  .logoin_page_header{
    display: flex;
    flex-direction: column;
  }
  .fade.login-popup.modal.show .modal-content {
    height: 100vh;
    width: 100% !important;
    border-radius: 0px;
    border: none;
  }

  .popup-cart.modal.show .modal-dialog {
    z-index: -1;
  }

  .popup-cart.modal.show .modal-footer {
    position: fixed;
    bottom: 0rem;
    width: 100%;
    z-index: 100;
    background: white;
  }

  .popup-cart .modal-content {
    width: 375px;
  }
  .modal-dialog.modal-dialog-centered {
    margin: 0;
    max-width: 100%;
  }
  .modal-dialog.modal-dialog-centered .modal-header {
    border-bottom: none;
    /* display: none !important; */
  }
  .modal-dialog.modal-dialog-centered .modal-header .modal-title.h4 {
    display: none;
  }
  .modal-dialog.modal-dialog-centered
    .modal-header
    .modal-tile-back.modal-title.h4 {
    display: block;
  }
  form.login-form {
    padding: 4px 22px;
  
  }
  .search-div input {
    width: 100%;
  }
  .search-mobile-main {
    padding-left: 12px;
    padding-right: 12px;
  }
  .login-head__text {
    color: #333;
    font-size: 21px;
    line-height: normal;
    margin: 0;
    text-align: center;
  }
  .weight--light {
    font-weight: 200 !important;
  }
  .login-head {
    padding: 42px 24px 0;
    display: block;
  }
  .login-header-main {
    /* text-align: center;
    padding: 20px 0; */
    display: none;
  }
  .loginfooter {
    text-align: center;
    display: block;
  }
  .login_page_right{
    background-color: #fff;
    width: 100%;
  }
  .modal-content .login_apge_left{
    width: 100%;
  }
  .modal-content .login_apge_left
  .loginfooter a {
    font-size: 12px;
    color: #3faa4a;
    margin-right: 10px;
  }
  button.btn.btn-next{
    margin-bottom: 43px;
  }
  .input-prefix{
    padding-left: 25px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    display: flex;
  }
  .login_page_right img{
    margin-top: 57px;
  }
  .login-help {
    display: block;
    font-size: 32px;
    line-height: 1.6;
    color: #fff;
    padding-top: 0;
  }
  .weight--semibold {
    font-weight: 500 !important;
  }
  .loginfooter.modal-footer {
    display: none;
  }
  .nav-mobile-location {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
  }
  .form-controll {
    border: 1px solid #ddd;
    padding: 8px;
  }
  .form-controll .search-icon {
    left: 22px;
    width: 25px;
    height: 25px;
  }
}
