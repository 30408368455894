.brand-heading
{
    color: rgb(28, 28, 28);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 150px;
    
}
.brand-card
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.brand-name
{
    margin-top: 8px;
    font-size: 16px;
    margin-bottom: 8px;
    cursor:pointer;
    color:#666666;
}
.brand-name:hover
{
    color:var(--primary_color)
}