.info-banner {
    padding: 30px 0px;
    background: radial-gradient(circle, rgba(168, 40, 139, 1) 0%, rgba(131, 51, 201, 1) 100%);
}

.info-banner-container p {
    text-align: center;
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 24px;
}

.top-info-container {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    background: #fff;
}

.top-info-container .d-flex {
    align-items: center;
}

p.subcategory-title {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}

.top-info-container label {
    margin: 0px;
    font-size: 12px;
    color: #666666;
}

.gridlayout .categories-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.gridlayout .subcat-item-container {
    border: 1px solid #ddd;
    margin-right: 20px;
    margin-bottom: 20px;
}

.subcategories-list.gridlayout {
    padding: 30px;
}

form.login-form {
    padding: 10px 60px;
}

form.login-form p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

button.btn.btn-next {
    background-color: #FFC806;
    color: #fff;
    width: 100%;
    opacity: 1;
    padding: 10px 0px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    margin-bottom: 62px;
}

.product-item a {
    color: #000;
    text-decoration: none;
}

.user-wallet {
    background-color: #f9f9f9;
    color: #333;
    border-radius: 3px;
    border: 1px solid #eee;
    margin: 16px 16px 40px;
    padding: 16px;
}

.user-wallet__amount {
    color: #000;
    font-size: 32px;
    font-weight: 500;
    margin-top: 4px;
}

.user-wallet__balance {
    color: #666;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 12px 0 16px;
    text-align: center;
}

.profile-nav__details-block {
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.profile-nav__details-block .account-profile__img {
    height: 90px;
    width: 90px;
}

.profile-nav__details-block .account-profile__phone {
    font-size: 12px;
    padding-top: 10px;
}

.profile-nav__list {
    line-height: 53px;
    list-style: none;
}

.list-unstyled {
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
    padding-left: 0;
}

.profile-nav__list-item {
    border-top: 1px solid #eee;
    cursor: pointer;
    font-size: 14px;
    display: list-item;
    color: rgb(102, 102, 102);
    text-decoration: none;
}

.profile-nav__list-item .item-text {
    padding-left: 0 20px;

}

.my-profile__right_rn {
    border-left: 1px solid #eee;
}

.my-profile_rn {
    margin-top: 119px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.my-profile_mobile {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
   
}

.my-wallet {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

.profile-nav__list-item-active {
    border-top: 1px solid #eee;
    cursor: pointer;
    font-size: 14px;
    display: list-item;
    color: #333;
    background-color: #f9f9f9;
    text-decoration: none;
}

.no-address {
    margin: 0 auto;
    padding-top: 82px;
    text-align: center;

}

.no-address__heading {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.no-address__sub-heading {
    color: #999;
    font-size: 14px;
    margin-bottom: 30px;
}
.no-orders {
    align-items: center;
    background-image: url(https://merchantdash.dunzo.in/static/media/zero-state.4bbeb9fd.svg);
    background-size: 100% 100%;
    display: flex;
    height: 212px;
}
.para-table-nodata {
    color: #9fa3af;
    font-size:20px;
}
.hnxUxX{
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
}
.cWRDMW {
    display: inline-block;
    text-align: center;
    color: rgb(105, 105, 105);
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(207, 207, 207);
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    border-radius: 12px;
    padding: 12px 16px;
}
.btn--link {
    background: #0c831f;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 8px 10px;
    text-align: center;
    /* margin-bottom: 10px; */
}

.btn--link:hover {
    background: white;
    color: #0c831f;
    border: 1px solid #0c831f;
}

.my-addresses {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    margin-bottom: 24px;
}

.my-addresses__new-address-btn {
    border: none;
    font-size: 13px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    color: #0c831f;
    cursor: pointer;
}

.my-addresses__new-address-btn-checkout {
    border: none;
    font-size: 13px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    color: #0c831f;
    border: 1px solid #0c831f;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-icon {
    color: #0c831f;
}

.my-addresses__new-address-btn:hover {
    background-color: #f9f9f9;
}

.address-item-container {
    background-color: #fff;
    border-top: 1px solid #eee;
    padding: 16px 20px;
}

.address-item {
    font-size: 14px;
}

.address-item__label {
    color: #333;
    font-weight: 500;
    line-height: normal;
    margin-left: 36px;
}

.address-item__value {
    color: #666;
    line-height: normal;
    margin-left: 36px;
    margin-top: 8px;
}

.text--dark-gray {
    color: #333
}

.Action-address {
    border-bottom: 1px solid black;
    cursor: pointer;
}

.address-title {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
}

.addres-subtitle {
    color: rgb(130, 130, 130);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
}

.select-control {
    color: rgb(184, 184, 184);
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-bottom: 16px;
    border-radius: 8px
}

.form-main {
    margin-top: 24px;
    margin-bottom: 10px;
}

.save-address {
    color: rgb(130, 130, 130);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 9px;
}

.address-1 {
    background: rgb(255, 255, 255);
    padding: 6px 16px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    text-align: center;
    color: rgb(28, 28, 28);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.address-2 {
    background: rgb(255, 255, 255);
    padding: 6px 16px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    border: 1px solid rgb(49, 134, 22);
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    text-align: center;
    color: rgb(28, 28, 28);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.btn-address {
    padding: 8px 16px;
    background-color: rgb(49, 134, 22);
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.btn-address:hover {

    background-color: white;
    color: rgb(49, 134, 22);
}

.change-location-title {
    font-family: okra;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.non-serviceable-step {
    text-align: center;
    width: 100%;
}

.stores{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    gap: 20px;
    padding: 10px;
    &:hover{
        background-color: #cbcbcb;
    }
}

.stores p{
    margin-bottom: 0px;
}

.stores-container {
    height: 200px;
    overflow-y: auto;
}

.map-pin{
    flex-shrink: 0;
    width: 25px;
}

.ns--image {
    width: 168px;
    margin-bottom: 6px;
}

.ns-exclamation {
    color: #333;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 4px;
    font-weight: 500;
}

.location__separator {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 19px;
    padding: 16px 12px;
    overflow: hidden;
    text-align: center;
}

.modal-back {
    color: #0c831f;
    margin-left: 0;
    cursor: pointer;
}

.oval-container {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.oval {
    width: 28px;
    height: 28px;
    border: solid 1px #ccc;
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    position: relative;
}

.oval .separator-text {
    position: relative;
    display: inline-block;
}

.or {
    width: 16px;
    height: 13px;
    font-family: okra;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bbb;
    text-align: center;
}

.cart-timer {
    display: flex;
    gap: 20px;
    align-items: center;
}

.cart-timer-icon {
    padding: 0 25px;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.bill-details {
    background-color: rgb(245, 247, 253);
    padding: 20px 0;
    margin-bottom: 15px;
}

.bill-head {
    color: rgb(0, 0, 0);
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  
    padding-top: 12px;
    font-family: 'inter', sans-serif;
    text-transform: uppercase;
}

.gap-10 {
    gap: 10px;
}

.gap-30 {
    gap: 30px;
}

.f_roboto {
    font-family: 'Roboto', sans-serif;
}

.f_12_500 {
    font-size: 12px;
    font-weight: 500;
    background-color: #DAE3FF;
    padding: 0 34px;
}


.f_13_700 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'inter', sans-serif;
}

.f_13_300 {
    font-size: 13px;
    font-weight: 300;
}

.f_13_400 {
    font-size: 13px;
    font-weight: 400;
}

.f_13_500{
    font-size: 13px;
    font-weight: 500;
}

.f_15_400 {
    font-size: 15px;
    font-weight: 400;
}

.f_15_600 {
    font-size: 15px;
    font-weight: 600;
}

.f_18_400 {
    font-size: 18px;
    font-weight: 400;
}

.f_18_500 {
    font-size: 18px;
    font-weight: 500;
}

.f_20_400 {
    font-size: 20px;
    font-weight: 400;
}

.f_20_600 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.f_25_600 {
    font-size: 25px;
    font-weight: 600;
}

.circle-map {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    border: 3px solid #04B70069;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1.5px solid #E1A647;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #6A6A6A;
}

.pointer {
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.free {
    background-color: var(--primary_color);
    color: white;
    min-width: 40px;
    padding: 1px 6px;
    border-radius: 2px;
    text-align: center;
    flex-shrink: 0;
}

.saved {
    font-size: 9px;
    font-weight: 600;
    color: rgb(37, 111, 239);
    background-color: rgb(237, 244, 255);
    display: flex;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 6px;
    min-width: 50px;
}
.discount-comment{
    margin-top: 10px;
    color: #2575f0;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
}

.flex-1{
    flex: 1;
}

.fullCentered{
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowBetweenStart{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.rowCenter {
    display: flex;
    align-items: center;
    padding: 7px 0;
}
.rowCenter span{
    font-size: 15px;
    font-weight: 500;
    font-family: 'inter', sans-serif;
}
.rowCenter p{
    margin-bottom: 0;
}

.rowStart{
    display: flex;
    align-items: flex-start;
}

.column{
    display: flex;
    flex-direction: column;
}

.desktop{
    display: block;
}

.mobile{
    display: none;
}

.location-modal {
    width: 40px;
    height: 40px;
}

.delvery--para {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.grand-total {
    display: flex;
    margin-top: 12px;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 500;
    border-top: 1px dashed #B5B5B5;
}

.spike-comment{
    text-align: center;
    padding: 5px;
    margin: 10px 0px;
    background: #e8ecf7;
    color: #d54a4a;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
}

.cuppons-aply {
    color: rgb(130, 130, 130);
    padding: 8px 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: rgb(252, 252, 252);
    font-weight: 500;
    text-align: center;
    font-size: 12px;
    border: 1px dashed #ddd;
}

.CancellationPolicy {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    font-family:'inter', sans-serif;
    padding: 34px 34px 10px;
}
.modal-footer{
    border-top: none !important;
    margin: 0;
    padding: 0;
    border: none;
    text-align: center;
    color: #fff;
}

.CancellationPolicy-content {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgb(130, 130, 130);
    background-color: rgb(255, 255, 255);
    padding: 0 34px 34px 34px;
}

.pac-container {
    z-index: 5000;
}

.add-address-main {
    background-color: rgb(255, 255, 255);
    max-height: 560px;
    overflow-y: auto;
}

.cart-address {
    display: flex;
    align-items: center;
    gap: 20px;
}

.left-arrrow {
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-family: CustomFont;
    cursor: pointer;
}

.left-address {
    font-size: 16px;
    line-height: 20px;
    font-family: Okra;
    font-weight: 700;
    color: rgb(31, 31, 31);
}

.add-address-nav {
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px;
}

.add-icon-nav {
    font-size: 40px;
    color: rgb(12, 131, 31);
}

.address-head-nav {
    color: rgb(0, 0, 0);
    line-height: 20px;
    font-size: 16px;
    font-family: Okra-Regular;
}

.address-p {
    padding: 16px;
    background-color: rgb(238, 238, 238);
    line-height: 16px;
    font-size: 12px;
    color: rgb(153, 153, 153);
    font-family: Okra-Regular;
}

.address-ass {
    margin-bottom: 8px;
    line-height: 20px;
    font-size: 16px;
    font-family: Okra-Bold;
    color: rgb(0, 0, 0);
}

.address-detail {
    line-height: 16px;
    font-size: 15px;
    color: rgb(102, 102, 102);
    font-family: Okra-Regular;
}

.address-main {
    padding: 16px
}

.change-nav {
    cursor: pointer;
    color: rgb(12, 131, 31);
    padding: 0px;
}

.payments-heading {
    color: rgb(28, 28, 28);
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    line-height: 32px;
    padding: 12px 2px;
}

.checkout-left-payment {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
}

.checkout-cart {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.checkout-step--address {
    padding: 10px;
}

.checkout-step-name {
    font-size: 18px;
    font-weight: 500;
    vertical-align: middle;
}

.selected-address {
    margin-top: 6px;
    color: rgb(153, 153, 153);
    overflow-wrap: break-word;
}

.save-address-ass {
    font-weight: 500 !important;
}

.two, .one{
    margin: 50px 0px;
}

.scroll {
    max-height: 100vh;
    overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    width: 0px;
}

.checkout-card {
    border-radius: 10px;
    box-shadow: 0 4px 14px 9px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
}

.bg-img {
    background-image: url(../Components/Navbar/images/Mask\ group.png);
    background-size: cover;
    padding: 80px 25px 40px 25px;
    background-repeat: no-repeat;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.bg-img-ord-detail {
    background-image: url(../Components/Navbar/images/Mask\ group.png);
    background-size: cover;
    padding: 60px 20px 20px 20px;
    background-repeat: no-repeat;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    text-align: center;
}

.instructions {
    border: 1px solid #85CB8C;
    width: 122px;
    height: 110px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px 19px 10px 38px;
    margin-bottom: 20px;
    width: calc(100% - 32px);
}

.payment-mode:hover {
    box-shadow: rgba(0, 0, 0, 0.11) 6px 0px 7px 2px;
    border: 1px solid var(--primary_color);
}

.coupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary_color);
    padding: 3px 19px;
    gap: 13px;
    border-radius: 5px;
    margin-top: 30px;
    background: #EAF3FE;
    cursor: pointer;
}

.remove:hover{
    text-decoration: underline;
}

.coupon-error{
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 10px;
}

/* Coupon Off canvas */

.coupon-header{
    padding: 32px;
    position: sticky;
    background-color: white;
    top: 0;
    margin-bottom: 40px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

#offcanvasCoupon{
    width: 450px;
}

.offcanvas-body.coupon-body{
    padding: 0px;
}

.coupon-input{
    padding: 0px 20px;
    height: 50px;
    border: 1px solid #d4d5d9;
    flex: 1;
    outline: none;
}

.coupon-close{
    margin-bottom: 30px;
}

.coupon-input::placeholder{
    font-size: 16px;
    font-weight: 500;
    color: #d4d5d9;
}

.coupon-btn{
    background-color: var(--primary_color);
    color: white;
    height: 50px;
    width: 110px;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.apply-btn:hover{
    box-shadow: 0 2px 8px #d4d5d9;;
}

.coupon-list{
    margin: 0px 32px 32px 32px;
    border: 1px solid #d4d5d9;
    padding: 30px 0px 0px 30px;
}

.coupon-amt{
    margin-bottom: 9px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #3e4152;
}

.coupon-item{
    padding: 0px 30px 35px 0px;
    border-bottom: 1px dashed #a9abb2;
    margin-bottom: 40px;
}

.coupon-item p{
    font-size: 13px;
    font-weight: 300;
    line-height: 15px;
}

.coupon-heading{
    color: #7e808c;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 28px;
}

.more{
    color: #5d8ed5;
    font-size: 12px;
    font-weight: 700;
    display: block;
}

.apply-btn{
    margin-top: 15px;
    color: var(--primary_color);
    padding: 0px 15px;
    border: 1px solid var(--primary_color);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    height: 35px;
    background-color: white;
}

.ticket{
    min-width: 130px;
    max-width: 180px;
    height: 35px;
    margin-bottom: 15px;
    display: flex;
    background-color: #ebfeeb;
}

.ticket-icon{
    width: 40px;
    border: 1px solid var(--primary_color);
    border-right: none;
    border-radius: 0px 7px 7px 0px;
    padding: 0px 2px;
}

.ticket-text{
    flex: 1;
    border: 1px solid var(--primary_color);
    border-radius: 7px 0px 0px 7px;
    border-left: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    /* color: #d4d5d9; */
    color: #949494;
}

.delivery-note {
    display: flex;
    align-items: center;
    border: 1px solid var(--primary_color);
    padding: 3px 19px;
    gap: 13px;
    border-radius: 5px;
    margin-top: 30px;
}

.delivery-options {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.express-in{
    color: #fd9904;
    background-color: #ffefb1;
    border: 1px solid #ffefb1;
    padding: 2px 5px;
    text-align: center;
    border-radius: 3px;
    margin-top: 10px;
}

.confirm-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #005CEA;
    color: white;
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
}

.confirm-btn.disabled {
    opacity: 0.5; 
    cursor: not-allowed; 
    pointer-events: none;
}

.confirm-btn:hover {
    background-color: white;
    color: var(--primary_color);
    box-shadow: #00000040 0px 4px 4px 0px;
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary_color);
    border-radius: 5px;
    background-color: #EBFEEB;
    padding: 9px 6px;
    height: 70px;
    max-width: 65px;
}

.img-container img {
    min-width: 74px;
    max-height: 68px;
    object-fit: contain;
}

.item-name {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75px;
}

.item-container {
    margin-top: 25px;
    padding-bottom: 25px;
    border-bottom: 0.5px solid black;
}

.address-text {
    width: 60%;
    flex-wrap: wrap;
}

.checkot-cart-title {
    font-weight: 100;
}

.checkout-img {
    padding: 0 12px;
    width: 84px;
    height: 57px
}

.checkout-cart-itme-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-checkout {
    height: 52px;
    cursor: pointer;
    background-color: rgb(67, 121, 40);
    color: white;
    font-size: 18px;
}

.button-checkout-disbled {
    height: 52px;
    cursor: pointer;
    background-color: rgb(204, 204, 204);
    color: white;
    font-size: 18px;
}

.payment-main {
    margin-top: 100px;
}

.relclass {
    position: relative;
}

.subcategories-list {
    position: sticky;
    top: 140px;
}

.catdflex {
    display: flex;
    border: 1px solid #ddd;
}

.catfl-left {
    flex: 1;
}

.catfl-right {
    flex: 3;
    background: #f8fbf9;
}

.subcategories-list-mobile {
    position: sticky;
    top: 200px;
}

.pad-10 {
    padding-top: 10px;
}

.color-error {
    color: red;
}

@media screen and (max-width: 767.98px) {
    .gridlayout .categories-container {
        display: grid;
        grid-template-columns: auto auto;
    }

    .desktop{
        display: none;
    }
    
    .mobile{
        display: block;
    }

    .btn--link {
        padding: 8px 7px;
        width: 135px;
        flex-shrink: 0;
    }

    .img-container img{
        min-width: 55px;
        max-height: 55px;
    }

    .item-name {
        max-width: unset;
        text-wrap: wrap;
        white-space: unset;
    }

    .one{
        margin: 30px 0px;
    }

    .two{
        margin: 0px;
    }

    .scroll {
        max-height: none;
        height: -webkit-fill-available;
    }

    .one {
        order: -1;
    }

    .CancellationPolicy-content{
        margin-bottom: 100px;
    }
    
}

@media screen and (max-width: 490px) {
    .cat-product {
        width: 80px;
        height: 80px;
        text-align: center;
    }
}

.categarios-sub {
    margin-top: 100px;
    background-color: #f9f9f9;
    position: sticky;
    top: 100px;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    z-index: 900;
}

.subcat-items {
    display: flex;
    padding-left: 20px;
}

.subcat-item {
    padding: 14px;
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter', sans-serif;
}

.subcat-items ul {
    padding-left: 0px;
}

.subcat-items a {
    color: #666666;
    text-decoration: auto;
    font-size: 14px;
}

section.sub-categories-menu {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 86px;
    background: #CBDCF7;
    z-index: 999;
}

.no-after::after {
    display: none;
}

.categarios-mobile {
    background-color: #f9f9f9;
    position: sticky;
    transition: transform 0.5s ease-in-out;
    z-index: 900;
}

.categarios-sub ul {
    text-decoration: none;
}

.list {
    list-style: none;
    color: #666;
    font-size: 17px;
    margin-left: 17px
}

.cat-sub-main {
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    position: relative;
}

.navbar-section {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99;
}

.select-sub {
    background-color: #f7f7f7;
    width: 100px;
    border: none;
}

.favour {
    text-decoration: line-through;
}

.breadcrumb-item.active {
    font-size: 12px;
    color: #999999;
    font-weight: 500;
    line-height: 16px;
}


.breadcrumb {
    display: flex;
    align-items: center;
}

li.breadcrumb-item {
    margin: 0;
    font-size: 12px;
}

li.breadcrumb-item a {
    color: #363636;
    text-decoration: auto;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    line-height: 16px;
}

.search-container {
    margin: auto;
    max-width: 1120px;
    padding-top:18px;
}
.inputV2-base-inputRow {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom:10px;
}
.addressFormUI-base-formHeader {
    font-size: 12px;
    font-weight: 700;
    color: #282c3f;
}
.addressFormUI-base-addressTypeIcon {
    padding: 4px 16px;
    border: 1px solid #282c3f;
    border-radius: 20px;
    font-size: 12px;
    margin-right: 16px;
}
.addressFormUI-base-addressTypeIcon.addressFormUI-base-selectedAddressType {
    border: 1px solid var(--primary_color);
    color: var(--primary_color);
    font-weight: 700;
}
.addressStripV2-base-desktopContainer {
    margin-bottom: 8px;
    background-color:#FFF;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #eaeaec;
    cursor: pointer;
}
.addressStripV2-base-title {
    font-size: 14px;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.inputV2-base-input {
    width: 100%;
    font-size: 14px;
    padding: 12px;
    border: 1px solid #d4d5d9;
    background-color: transparent;
    font-weight: 300;
    border-radius: 4px;
    line-height: 14px;
}
.CheckDeliveryModalV2-base-checkBtn {
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: 33px;
    top:17px;
    height: 14px;
    line-height: 14px;
    color: var(--primary_color);
    cursor: pointer;
}
.CheckDeliveryModalV2-base-inValid {
    color: #94969f;
}
.wishlist-container {
    margin: auto;
    max-width: 1090px;
}

.wishlist-container .catagery-items-grid {
    padding-right: 12px;
}

.search-container .catagery-items-grid {
    padding-right: 12px;
}

p.showing-results {
    padding-left: 12px;
    padding-top: 10px;
    margin-bottom: 0px;
    font-size: 15px;
    color: #1f1f1f;
    font-weight: 700;
}

.breadcrumbs h1 {
    font-size: 3rem;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 3.4rem;
    color: #222222;
    font-weight: 500;
}

.breadcrumb-sub {
    background-image: url("../Components/images/bg-breadcrumbs.jpg");
    padding: 3rem;
}

.breadcrumbs-product-detail {
    margin-top: 100px;
    background-image: url("../Components/images/bg-breadcrumbs.jpg");
    padding: 4rem;
}

.products-container h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    color: #212529;
    margin-bottom: 40px;
}
.product-bottom {
    padding: 24px;
}

a.link-none {
    text-decoration: none;
}

a.gotohome {
    color: var(--primary_color);
}

img.dog_png {
    max-width: 200px;
    float: right;
    margin-top: 20px;
}

h1.not-found-heading {
    padding-top: 50px;
}

.categories-section {
    margin-top: 60px;
}

@media screen and (min-width: 992px) {
    .navbar-container .navbar {
        min-height: 85px;
    }

    .banners-section {
        min-height: 252px;
    }

    .categories-section {

        min-height: 188px;
    }


    .footer-section {
        min-height: 330px;
    }
}

.image-privacy {
    width: 100%;
    height: 70vh;
}

.margin-about-us {
    margin-top: 100px;
}

.col-backgorung {
    background-color: #E3E3E3;
    padding: 12px;
}

.top-info-container-mobile {
    padding: 12px;
    border-top: 0.5px solid #e8e8e8;
    border-bottom: 0.5px solid #e8e8e8;
}

.top-info-container-mobile p {
    color: #1f1f1f;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 991.98px) {
    .pdfl {
        display: flex;
        flex-direction: column;
    }

    .product-description,
    .horizontal-line {
        display: none;
    }

    .pdfl .pdfl-right {
        border-left: none;
    }

    .pdfl-right .product-detail-info {
        padding: 0px;
    }

    .product-detail-actions {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 767.98px) {
    .categories-section {
        margin-top: 20px;
    }
    
    .categories-container {
        margin-bottom: 20px;
    }
    .delvery--para {
        width: 100%;
    }

    .products-container h2 {
        font-size: 17px;
        font-weight: 800;
    }

    p.price {
        font-size: 13px;
    }

    p.produc-price {
        font-size: 12px;
    }

    .top-info-container-mobile {
        padding: 12px;
        border-top: 0.5px solid #e8e8e8;
        border-bottom: 0.5px solid #e8e8e8;
    }

    .top-info-container-mobile p {
        color: #1f1f1f;
        font-weight: 600;
        font-size: 13px;
    }

    .categories-container .container {
        /* padding: 0px; */
    }

    .subcategories-list {
        padding-top: 0px;
        top: 121px;
        width: 70px;
    }

    .subcat-item-container img {
        max-width: 40px;
        margin-bottom: 4px;
    }

    p.sub-p {
        font-size: 11px;
    }

    .subcat-item-container {
        padding: 15px 2px;
        border: none;
    }

    .subcat-item-container.active p.sub-p{
        font-weight: 700;
    }

    .catfl-right {
        flex: 4;
    }

    .top-info-container {
        display: none;
    }

    .search-container .product-item {
        max-width: 165px;
    }

    .search-container .catagery-items-grid {
        padding: 12px;
    }

    .wishlist-container .catagery-items-grid {
        padding: 12px;
    }

    .product-detail-actions {
        flex-direction: column;
        align-items: center;
    }

    .pdf1-right,
    .pdf1-left {
        width: 100%;
        padding: 0px;
    }

}

@media screen and (min-width: 900px) {
    .login-popup.modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;
    }
}