@import '../../Style\ Guide/variables.scss';

.pointer {
    cursor: pointer;
}

.blog-grid,
.detail-list {
    display: grid;
    grid-template-columns: 65fr 35fr;

    .more {
        border-left: 0.5px solid #00000070;
        padding-left: 50px;
        padding-top: 35px;
        position: relative;
        color: unset;
    }

    .more>div {
        position: sticky;
        top: 100px;
    }

    .list {
        padding-top: 35px;
        margin-right: 100px;
        margin-left: auto;
        ul {
            display: flex;
            list-style: none;
            gap: 22px;
            padding-bottom: 12.7px;
            padding-left: 0px;
            margin-bottom: 0px;
            font-size: $h6-size;
            font-weight: 400;
        }

        .blog {
            border-top: 0.5px solid #00000070;
            padding: 35.8px 0px;
            cursor: pointer;
        }

        .img-container {
            margin-right: 8px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .info-top {
            display: flex;

            span {
                color: #242424b2;
                font-size: $h6-size;
                font-weight: 400;
            }

        }

        .content {
            display: flex;
            gap: 68px;
            align-items: flex-start;

            h3 {
                font-size: $h5-big-size;
                font-weight: $bold;
                margin-top: 10px;
                margin-bottom: 16px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                width: 34vw;
                line-height:24px;
                color:#000;
            }

            h3:hover {
                text-decoration: underline;
            }

            p {
                font-size: $h5-small-size;
                font-weight: 400;
                line-height:normal;
                color: #242424b2;
            }

            .overflowWrap {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .img-container {
                width: 112px;
                height: 112px;
                border-radius: 0%;
                flex-shrink: 0;

                img {
                    border-radius: 0%;
                }
            }
        }

    }

    .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 13px;
        font-weight: 400;
        color: #242424b2;

        div:last-child {
            margin-right: 200px;
        }
    }

    h5 {
        font-size: $h5-small-size;
        font-weight: $semi-bold;
        margin-bottom: 12px;
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        font-size: $h6-size;
        font-weight: 400;
        padding-bottom: 20.8px;
    }

    .see-more {
        color: #22C065;
        font-size: $h6-size;
        font-weight: 400;
    }

    .topics {
        margin-top: 33px;
        display: flex;
        flex-wrap: wrap;

        div {
            margin-bottom: 18px;
            padding: 0px 10px;
            border-right: 0.5px solid #000000;
            font-size: 11px;
            font-weight: 400;
            cursor: pointer;
        }

        :nth-child(1) {
            padding-left: 0px;
        }

        div:last-child {
            border-right: none;
        }
    }
}

.blog-detail {
    padding: 50px 200px;
    h2{
        font-size: 42px;
        font-weight: 700;
        line-height: 52px;
    }
}

.detail-list {
    grid-template-columns: 1fr;

    .list {
        margin-right: 0px;
    }
}

.topic {
    padding: 9px 14.7px;
    background-color: #F2F2F2;
    border-radius: 1000px;
    cursor: pointer;
}

.blog-detail {
    .topic {
        margin-right: 8px;
    }

    .recommended {
        font-size: 22px;
        font-weight: $semi-bold;
        margin-top: 76px;
        margin-bottom: 20px;
    }
}

.detail-top {
    margin-top: 32px;
    margin-bottom: 52px;
    display: flex;
    gap: 28px;

    img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
    }

    p {
        font-size: 15.13px;
        font-weight: 400;
        color: #242424;
        margin-bottom: 2.5px;
    }

    span {
        font-size: 13.34px;
        font-weight: 400;
        color: #6B6B6B;
        margin-right: 8px;
    }
}

.article {
    padding: 42px 0px;

    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        text-align: justify;
    }

    h2,
    h1 {
        font-size: 20px;
        font-weight: 700;
    }
}

@media screen and (max-width: 767.98px) {

    .blog-grid,
    .detail-list {
        grid-template-columns: 1fr;

        .more {
            border: 0px;
            padding-left: 0px;
        }

        .list {
            margin-right: 0px;
        }

        .bottom {
            div:last-child {
                margin-right: 0px;
            }
        }
    }

    .blog-detail {
        padding: 50px 35px;
    }

    .more>div {
        position: relative;
        top: 0px;
    }
}

@media screen and (max-width: 575.98px) {

    .blog-grid,
    .detail-list {
        padding: 0px 24px;

        .list {
            padding-top: 70px;

            .info-top {

                span {
                    font-size: 13px;
                    font-weight: 400;
                }

            }

            .content {
                gap: 44px;

                h3 {
                    font-size: $h5-big-size;
                    font-weight: $bold;
                    margin-top: 10px;
                    margin-bottom: 16px;
                    text-wrap: wrap;
                    width: 52vw;
                    font-size: $h5-small-size;
                }

                p {
                   
                }

                .img-container {
                    width: 64px;
                    height: 64px;
                }
            }
        }
    }

    .detail-list {
        padding: 0px;
    }

    .article {
        padding: 42px 0px;
    
        p {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .blog-detail {
        h2{
            font-size: 22px;
            font-weight: $bold;
            line-height: 31px;
        }
    }
}