.banner-img img {
    width: 100%;
}
.banner_img img{
    width: 100%;
    height: 100vh;
}
.slick-next {
    right: 0px;
}
.slick-prev {
    left: 0px;
}
.banners-container {
    margin-bottom: 30px;
}
.section-fullwidth-image img {
    max-width: 100%;
    width: 100%;
}
.section-fullwidth-image {
    margin-bottom: 30px;
}
@media screen and (max-width: 767.98px) {
    .banner-img img {
        max-width: 100%;
    } 
    .slick-slider .slick-prev:before, .slick-slider .slick-next:before{
        margin-left: 5px;
    }
    .banners-column-container img {
        max-width: 100%;
    }
}