.wishlist-product {
    padding: 12px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.wishlist-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.nav-heart-icon {
    width: 40px;
    height: 40px;
    color: #101A39;
}

.icon-wishlist svg {
    width: 16.99px;
    height: 16.07px;
    float: right;
    /* position: absolute; */
    right: 2px;
    top: 2px;
    color: #000000
}

.product-item {
    position: relative;
}