.pointer {
  cursor: pointer;
}
.blog-grid .list .content p, 
.detail-list .list .content p {
    font-size: 16px;                      /* Sets the font size of the paragraph text */
    font-weight: 400;                     /* Sets the font weight to normal */
    line-height: normal;                  /* Sets the space between lines of text */
    color: rgba(36, 36, 36, 0.6980392157); /* Sets a semi-transparent dark color for the text */
}
.blog-grid,
.detail-list {
  display: grid;
  grid-template-columns: 65fr 35fr;
}
.blog-grid .more,
.detail-list .more {
  border-left: 0.5px solid rgba(0, 0, 0, 0.4392156863);
  padding-left: 50px;
  padding-top: 35px;
  position: relative;
  color: unset;
}
.blog-grid .more > div,
.detail-list .more > div {
  position: sticky;
  top: 100px;
}
.blog-grid .list,
.detail-list .list {
  padding-top: 35px;
  margin-right: 100px;
  margin-left: auto;
}
.blog-grid .list ul,
.detail-list .list ul {
  display: flex;
  list-style: none;
  gap: 22px;
  padding-bottom: 12.7px;
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
}
.blog-grid .list .blog,
.detail-list .list .blog {
  border-top: 0.5px solid rgba(0, 0, 0, 0.4392156863);
  padding: 35.8px 0px;
  cursor: pointer;
}
.blog-grid .list .img-container,
.detail-list .list .img-container {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-grid .list ul, .detail-list .list ul {
  display: flex;
  list-style: none;
  gap: 22px;
  padding-bottom: 12.7px;
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of items */
}

.blog-grid .list ul::-webkit-scrollbar, 
.detail-list .list ul::-webkit-scrollbar {
  display: none; /* Hides the scrollbar for a cleaner look */
}

.blog-grid .list ul li, .detail-list .list ul li {
  flex: 0 0 auto; /* Ensures that each item takes only the space it needs */
  cursor: pointer;
}

.blog-grid .list .img-container img,
.detail-list .list .img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.blog-grid .list .info-top,
.detail-list .list .info-top {
  display: flex;
}
.blog-grid .list .info-top span,
.detail-list .list .info-top span {
  color: rgba(36, 36, 36, 0.6980392157);
  font-size: 14px;
  font-weight: 400;
}
.blog-grid .list .content,
.detail-list .list .content {
  display: flex;
  gap: 68px;
  align-items: flex-start;
}
.blog-grid .list .content h3,
.detail-list .list .content h3 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 16px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 34vw;
  line-height: 24px;
  color: #000;
}
.blog-grid .list .content h3:hover,
.detail-list .list .content h3:hover {
  text-decoration: underline;
}
.blog-grid .list .content p,
.detail-list .list .content p {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(36, 36, 36, 0.6980392157);
  text-align:start;
}
.blog-grid .list .content .overflowWrap,
.detail-list .list .content .overflowWrap {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-grid .list .content .img-container,
.detail-list .list .content .img-container {
  width: 112px;
  height: 112px;
  border-radius: 0%;
  flex-shrink: 0;
}
.blog-grid .list .content .img-container img,
.detail-list .list .content .img-container img {
  border-radius: 0%;
}
.blog-grid .bottom,
.detail-list .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(36, 36, 36, 0.6980392157);
}
.blog-grid .bottom div:last-child,
.detail-list .bottom div:last-child {
  margin-right: 200px;
}
.blog-grid h5,
.detail-list h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}
.blog-grid .filters,
.detail-list .filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20.8px;
}
.blog-grid .see-more,
.detail-list .see-more {
  color: #22C065;
  font-size: 14px;
  font-weight: 400;
}
.blog-grid .topics,
.detail-list .topics {
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
}
.blog-grid .topics div,
.detail-list .topics div {
  margin-bottom: 18px;
  padding: 0px 10px;
  border-right: 0.5px solid #000000;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
}
.blog-grid .topics :nth-child(1),
.detail-list .topics :nth-child(1) {
  padding-left: 0px;
}
.blog-grid .topics div:last-child,
.detail-list .topics div:last-child {
  border-right: none;
}

.blog-detail {
  padding: 50px 200px;
}
.blog-detail h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
}

.detail-list {
  grid-template-columns: 1fr;
}
.detail-list .list {
  margin-right: 0px;
}

.topic {
  padding: 9px 14.7px;
  background-color: #F2F2F2;
  border-radius: 1000px;
  cursor: pointer;
}

.blog-detail .topic {
  margin-right: 8px;
}
.blog-detail .recommended {
  font-size: 22px;
  font-weight: 600;
  margin-top: 76px;
  margin-bottom: 20px;
}

.detail-top {
  margin-top: 32px;
  margin-bottom: 52px;
  display: flex;
  gap: 28px;
}
.detail-top img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.detail-top p {
  font-size: 15.13px;
  font-weight: 400;
  color: #242424;
  margin-bottom: 2.5px;
}
.detail-top span {
  font-size: 13.34px;
  font-weight: 400;
  color: #6B6B6B;
  margin-right: 8px;
}

.article {
  padding: 42px 0px;
}
.article p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
}
.article h2,
.article h1 {
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 767.98px) {
  .blog-grid,
  .detail-list {
    grid-template-columns: 1fr;
  }
  .blog-grid .more,
  .detail-list .more {
    border: 0px;
    padding-left: 0px;
  }
  .blog-grid .list,
  .detail-list .list {
    margin-right: 0px;
  }
  .blog-grid .bottom div:last-child,
  .detail-list .bottom div:last-child {
    margin-right: 0px;
  }
  .blog-detail {
    padding: 50px 35px;
  }
  .more > div {
    position: relative;
    top: 0px;
  }
}
@media screen and (max-width: 575.98px) {
  .blog-grid,
  .detail-list {
    padding: 0px 24px;
  }
  .blog-grid .list,
  .detail-list .list {
    padding-top: 70px;
  }
  .blog-grid .list .info-top span,
  .detail-list .list .info-top span {
    font-size: 13px;
    font-weight: 400;
  }
  .blog-grid .list .content,
  .detail-list .list .content {
    gap: 44px;
  }
  .blog-grid .list .content h3,
  .detail-list .list .content h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 16px;
    text-wrap: wrap;
    width: 52vw;
    font-size: 16px;
  }
  .blog-grid .list .content .img-container,
  .detail-list .list .content .img-container {
    width: 64px;
    height: 64px;
  }
  .detail-list {
    padding: 0px;
  }
  .article {
    padding: 42px 0px;
  }
  .article p {
    font-size: 14px;
    line-height: 22px;
  }
  .blog-detail h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 31px;
  }
}/*# sourceMappingURL=Blog.css.map */