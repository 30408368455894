form.otp-form {
  text-align: start;
  padding: 60px 55px 36px 55px;
}
.otp-form p{
  font-size: 40px;
  font-weight: 500;
  font-family: 'inter', sans-serif;
  color: #fff;
  padding-bottom: 25px;
  padding-left: 25px;

}
.otp-form .opt_number{
  font-size: 16px;
  font-weight: 300;
  font-family: 'inter', sans-serif;
}
.otp-form span
{
  font-size: 19px;
  font-weight: 500;
}
.fullCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}
h4.login-success {
  background-color: var(--primary_color);
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}
.form-control.otp-input {
  width: 50px;
  height: 67px;
  border: solid 1px var(--primary_color);
  border-radius: 26px;
  margin: 0 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}
.otpget_text{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 20px 0;
}
.otpget_text p{
   font-size: 18px;
   color: #fff;
   font-weight: 300;
   font-family: 'inter', sans-serif;
   margin-bottom: 0;
   padding-bottom: 0;
}
.otpget_text span{
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  font-family: 'inter', sans-serif;
}
@media screen and (max-width: 767.98px) {
  form.otp-form{
    padding: 10px 25px 20px 25px;
  }
  .otp-form p{
    font-size: 32px;
    padding-bottom: 13px;
  }
  button.btn.btn-next{
    margin-bottom: 15PX;
  }
  .otpget_text p{
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .otpget_text span{
    font-size: 18px;
  }

  
}
