.container-form {
    background: #fff;
    padding: 12px 0px;
    border-radius: 5px;
}

.loader-container {
    text-align: center;
}

.loader-container img {
    width: 200px;
    padding-top: 60px;
}

span.required-star {
    color: red;
}

.invalid-error {
    border: 1px solid var(--bs-danger-text, #ff0000);
    border-radius: 4px;
}

.invalid {
    color: var(--bs-danger-text, #ff0000);
}

.class-border {
    border-right: none;
}

.custom-input {
    border-radius: 0;
}

.input-group-append-custom {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px solid #DDD;
    border-top: 1px solid #DDD;
    border-right: 1px solid #DDD;

}

.input-box {
    position: relative;
    display: flex;
    align-items: center;
}

.input-box div{
    max-width: 100%;
    flex: 1;
}

.color-box {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.custom-input-class {
    padding-left: 35px;
}

.color-box div {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

.class-border {
    border-right: none;
}

.custom-input {
    border-radius: 0;
}

.list-container {
    padding: 0px;
    position: absolute;
    z-index: 2;
    width: 100%;
}

.search-list-item {
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px 10px;
    background: white;
}

button.btn.btn-primary {
    background: #005CEA;
    border-radius: 4px;
}

button.btn.btn-primary:hover {
    color: #005CEA;
    background: #fff;
    border: 1px solid #005CEA;
    border-radius: 4px;
}